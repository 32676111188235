







































import {Component, Ref, Vue} from "vue-property-decorator"
import {getModule} from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import PasswordService from "@/services/PasswordService"
import DialogModule from "@/store/DialogModule"
import Dialog from "@/models/vue/Dialog"
import StringTool from "@/services/tool/StringTool"
import SessionModule from "@/store/SessionModule"
import LoginService from "@/services/LoginService"
import CartModule from "@/store/CartModule"

@Component
export default class PasswordRecoveryView extends Vue {

	@Ref() readonly form!: HTMLFormElement
	lang: any = getModule(LangModule).lang
	newPassword: string = ""
	match: string = ""
	minLength: number = 4
	maxLength: number = 128

	created() {
		let sessionModule: SessionModule = getModule(SessionModule)
        sessionModule.session.token = ""
        sessionModule.saveSession()
        getModule(CartModule).clearCart()
	}

	rules = {
		required: (value: string) => !!value || this.lang.emptyLabel,
		min4Characters: (value: string) => value.length > this.minLength || StringTool.replaceDynText(this.lang.minimumLength, "%numberSlot%", "4")
	}

	basicRules = [
    (input: any) => { return !!input || this.lang.emptyLabel },
		(input: string) => { return input?.length > this.minLength || StringTool.replaceDynText(this.lang.minimumLength, "%numberSlot%", "4") }
	]

	patchPassword() {
		if ((this.form.validate()) && (this.$route.query.token != undefined)) {
			getModule(DialogModule).showDialog(
				new Dialog(this.lang.warning, this.lang.updatePasswordConfirmation, () => {
					PasswordService.passwordRecovery(this, this.$route.query.token.toString(), this.newPassword)
				})
			)
		}
	}

}
